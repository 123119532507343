const ptBR = {
    translation: {
        home: {
            mainSection: {
                title: "Soluções de comunicação omnichannel, gestão telecom e colaboração que vão melhorar a produtividade da sua empresa",
                subtitle: "As soluções Tellflex disponibilizam serviços e produtos para o mercado de telecom, de fácil customização, para uso imediato e sem burocracia."
            }
        }
    }
}

export default ptBR;