import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Title from "../components/Title";
import AboutCard from "../components/AboutCard";
import BannerSobre from "./../assets/images/banner-topo-sobre.png";

const About: React.FC = () => {
    return (
        <>
            <Header />
            <section className="tf-about-pt1">
                <div className="container">
                    <div className="d-flex flex-row flex-wrap responsivity">
                        <Title className="text-left tf-title title-page mt-5">
                            <div className="mt-5 tf-about-pt1-title">
                                Solução em nuvem completa, <br />
                                para atender empresas de todos <br />
                                os tamanhos e segmentos.
                            </div>{" "}
                            <div className="mt-5 tf-about-pt1-subtitle">
                                Todas as ferramentas de comunicação que as
                                empresas precisam, <br /> totalmente integradas
                                em um só lugar. Disponibilizamos soluções no
                                nosso
                                <br /> próprio Market Place, todas acessíveis
                                para qualquer lugar do planeta, por
                                <br /> qualquer dispositivo.
                            </div>
                            <div className="mt-5 tf-about-pt1-subtitle-bold">
                                Solução 100% disponível, em ambiente altamente
                                seguro, estável e escalável.
                            </div>
                        </Title>
                        <img
                            src={BannerSobre}
                            className="img-banner-desktop"
                            alt="banner"
                        />
                    </div>
                </div>
            </section>

            <section className="tf-about-pt2">
                <div className="container ">
                    <Title className="font-weight-bold text-left mt-1">
                        A empresa
                    </Title>
                    <div
                        className="mt-4 text-left"
                        style={{
                            fontSize: "20px",
                            lineHeight: "30px",
                            color: "#606167",
                        }}
                    >
                        A Telflex oferece soluções a nível global, sendo capaz
                        de disponibilizar soluções em nuvem para atender
                        empresas de todos os tamanhos e segmentos. Com DNA
                        genuinamente brasileiro, conhece a fundo como funciona o
                        mundo das operadoras e provedores em nível nacional.
                        <p />
                        Uma marca com selo Digivox, que atua há mais de 15 anos
                        no mercado e tem um leque de opções em soluções
                        integradas. Assim, os parceiros Telflex podem focar no
                        que é realmente importante: o relacionamento com seus
                        clientes. A Telflex cuida da tecnologia.
                        <p />
                        Todas as ferramentas de comunicação que as empresas
                        precisam, totalmente integradas em um só lugar.
                        Acessíveis para qualquer lugar do planeta, por qualquer
                        dispositivo.
                    </div>
                </div>
            </section>

            <section className="tf-about-pt3">
                <div className="container d-flex flex-row aboutWrap">
                    <AboutCard
                        smallerType={true}
                        ulType={false}
                        title="Missão"
                        text="Entregar soluções inovadoras que melhorem a experiência do cliente por meio de uma equipe comprometida e parcerias estratégicas."
                    />

                    <AboutCard
                        smallerType={false}
                        ulType={false}
                        title="Visão"
                        text="Ser referência por nossas soluções inovadoras de alto valor agregado, atuando globalmente como núcleo de sistema de comunicação, sendo líder em vendas no mercado empresarial nacional, através de alianças estratégicas e profissionais altamente capacitados."
                    />
                    <AboutCard
                        smallerType={true}
                        ulType={true}
                        title="Valores"
                        text=""
                    />
                </div>
            </section>

            <Footer />
        </>
    );
};

export default About;
