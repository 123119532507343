import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Title from "../components/Title";
import Text from "../components/Text";
import { RiArrowDownLine } from "react-icons/ri";
import BannerTopoSolucoesDesktop from "../assets/images/banner-topo-solucoes.png";
import IlustracaoFerramentas from "../assets/images/ilustracao-ferramentas.png";
import Telefonia from "./../assets/images/icones/01-icone-telefonia.png";
import ContactCenter from "./../assets/images/icones/02-icone-contact-center.png";
import Colaboracao from "./../assets/images/icones/03-icone-uc-colaboracao.png";
import Gestao from "./../assets/images/icones/04-icone-gestao-relacionamento.png";
import VideoConferencia from "./../assets/images/icones/05-icone-videoconferencia.png";
import ForcaVendas from "./../assets/images/icones/06-icone-forca-vendas.png";
import IconeChat from "./../assets/images/icones/icone-chat-contato.png";
import CallCenter from "./../assets/images/icones/07-icone-call-center.png";
import hardWareIcon from "../assets/images/icone-dobra-3-1.png";
import moneyIcon from "../assets/images/icone-dobra-3-2.png";
import energyIcon from "../assets/images/icone-dobra-3-3.png";
import coinIcon from "../assets/images/icone-dobra-3-4.png";
import SolutionsCard from "../components/SolutionsCard";

const Solutions: React.FC = () => {
    return (
        <>
            <Header />
            <section className="tf-solutions-pt1">
                <div className="container">
                    <div className="d-flex flex-row flex-wrap justify-content-center align-items-center h-100 responsivity">
                        <Title className="text-left tf-title title-page">
                            <div className="font-weight-bold ">
                                Nossas Soluções
                            </div>{" "}
                            <div className="tf-solutions-pt1-spacing" />
                            <div className="tf-solutions-pt1-subtitle">
                                A Telflex possui os serviços em nuvem completos
                                para
                                <br />
                                atender todos os tamanhos e segmentos, para que
                                a sua <br /> empresa precisa para se comunicar
                                com Colaboradores, <br /> Parceiros e clientes
                            </div>
                        </Title>
                        <img
                            src={BannerTopoSolucoesDesktop}
                            className="img-banner-desktop"
                            alt="banner"
                        />
                        {/* 
                        <img
                            src={BannerTopoSolucoesDesktop}
                            className="d-none img-banner-desktop-2"
                            alt="banner"
                        />
                        <img
                            src={BannerTopoSolucoesMobile}
                            className="d-none img-banner-mobile"
                            alt="banner"
                        /> */}
                    </div>
                </div>
            </section>
            <section className="tf-solutions-pt2">
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center">
                            <img
                                src={IlustracaoFerramentas}
                                className="img-brain"
                                alt="brain"
                            />
                        </div>
                        <div className="d-flex flex-column ml-lg-5 mt-5">
                            <Title className="text-left text-lg-left font-weight-bold">
                                Suas ferramentas de <br />
                                comunicação em um só lugar <br />
                            </Title>
                            <Text className="text-left text-lg-left tf-solutions-pt2-subText">
                                Tenha acesso a todas as ferramentas de
                                comunicação <br /> que você precisa, como
                                Telefonia, Comunicações <br />
                                Unificadas, Colaboração, Videoconferência,{" "}
                                <br /> Relacionamento com o Cliente e CX, em uma
                                única <br />
                                plataforma, disponível a qualquer momento, em um{" "}
                                <br /> ambiente seguro, estável e escalável.
                            </Text>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-solutions-pt3">
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 col-xl-4 d-flex justify-content-center justify-content-xl-start align-items-center">
                            <div className="d-flex flex-column align-items-center justify-content-center mb-5 mb-xl-0">
                                <Title className="text-center text-xl-left font-weight-bold">
                                    Benefício das <br />
                                    soluções na nuvem
                                </Title>
                                <a
                                    className="btn btn-primary btn-lg"
                                    href="https://materiais.digivox.com.br/telflex-campanha-de-lancamento-6-meses-gratis"
                                >
                                    FALE COM UM CONSULTOR
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-xl-8">
                            <div>
                                <div className="d-flex flex-wrap justify-content-center justify-content-xl-start">
                                    <div className="rounded-retangle">
                                        <img src={hardWareIcon} alt="icon" />
                                        <div className="rounded-retangle-text">
                                            Reduza infraes-
                                            <br />
                                            trutura de hardware
                                        </div>
                                    </div>
                                    <div className="rounded-retangle">
                                        <img src={coinIcon} alt="icon" />
                                        <div className="rounded-retangle-text">
                                            Diminua custos
                                            <br />
                                            com manutenção
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap flex-row-reverse justify-content-center justify-content-xl-start">
                                    <div className="rounded-retangle">
                                        <img src={energyIcon} alt="icon" />
                                        <div className="rounded-retangle-text">
                                            Economize no
                                            <br />
                                            consumo de energia
                                        </div>
                                    </div>
                                    <div className="rounded-retangle">
                                        <img src={moneyIcon} alt="icon" />
                                        <div className="rounded-retangle-text">
                                            Tenha novas
                                            <br />
                                            fontes de receita
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<section className="tf-solutions-pt3">
                <div className="container" style={{ position: "absolute" }}>
                    <Title className="text-left font-weight-bold">
                        Benefício das <br />
                        soluções na nuvem
                    </Title>
                    <Button color="primary" size="lg">
                        FALE COM UM CONSULTOR
                    </Button>
                </div>
                <div className="d-flex flex-column">
                    <div
                        className="container d-flex flex-row"
                        style={{
                            position: "relative",
                            left: "23%",
                            top: "30%",
                        }}
                    >
                        <div className="d-flex flex-row rounded-retangle font-weight-bold">
                            <img src={hardWareIcon} alt="icon" />
                            <div className="ml-4 tf-solutions-pt3-font-size-20">
                                Reduza infraes-
                                <br /> trutura de hardware
                            </div>
                        </div>
                        <div className="d-flex flex-row ml-4 rounded-retangle font-weight-bold">
                            <img src={coinIcon} alt="icon" />
                            <div className="ml-4 tf-solutions-pt3-font-size-20">
                                Diminua custos com manutenção
                            </div>
                        </div>
                    </div>

                    <div
                        className="container d-flex flex-row font-weight-bold"
                        style={{
                            position: "relative",
                            left: "53%",
                            marginTop: "3vh",
                        }}
                    >
                        <div className="d-flex flex-row rounded-retangle">
                            <img src={energyIcon} alt="icon" />
                            <div className="ml-4 tf-solutions-pt3-font-size-20">
                                Economize no consumo de energia
                            </div>
                        </div>
                        <div className="d-flex flex-row ml-4 rounded-retangle font-weight-bold">
                            <img src={moneyIcon} alt="icon" />
                            <div className="ml-4 tf-solutions-pt3-font-size-20">
                                Tenha novas fontes de receita
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-solutions-pt4">
                <div className="container ">
                    <Title className="font-weight-bold text-left">
                        Soluções que integram
                    </Title>
                    <div
                        className="mt-4 text-left"
                        style={{
                            fontSize: "20px",
                            lineHeight: "30px",
                            color: "#606167",
                        }}
                    >
                        Garanta uma plataforma de soluções integradas para que
                        os seus colaboradores e clientes estejam conectados de
                        qualquer lugar, a qualquer momento, através de
                        celulares, tablets, computadores ou outros dispositivos
                        conectados à internet.
                    </div>
                </div>
                    </section>*/}
            <section className="tf-solutions-pt5 ">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <SolutionsCard
                                icon={Telefonia}
                                title="Telefonia - PABX em nuvem"
                                text="Realize chamadas telefônicas através de uma rede conectada a internet e gerencie todos os recursos de ramais através de softphones ou dispositivos móveis.                                "
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <SolutionsCard
                                icon={ContactCenter}
                                title="Contact Center Omnichannel"
                                text="Realize a gestão do atendimento com a  unificação de todos os canais de forma simultânea
                                uma única plataforma: voz, chat, e-mail, sms, e-mail e mídias sociais. "
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <SolutionsCard
                                icon={Colaboracao}
                                title="Comunicações Unificadas e Colaboração"
                                text="Tenha toda a sua comunicação integrada em apenas uma plataforma. Realize chamadas de áudio, vídeo, converse por chat, compartilhe documentos através de um aparelho IP ou Softphone em dispositivos móveis."
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <SolutionsCard
                                icon={Gestao}
                                title="Gestão do Relacionamento
                                com o Cliente"
                                text="Monitore e gerencie todo o operacional ativo, suporte, back office e atendimento ao cliente com integração da comunicação omnichannel, automação de vendas, processos e ações de marketing. Acompanhe os passos do cliente com o registro da cada interação apresentada através da Jornada Visual."
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <SolutionsCard
                                icon={VideoConferencia}
                                title="Videoconferência"
                                text="Realize chamadas por vídeo e áudio entre pessoas que estão em lugares diferentes,através de um aparelho IP ou Softphone em dispositivos móveis, o que possibilita a unificação e integração entre equipes internas e externas da empresa."
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <SolutionsCard
                                icon={ForcaVendas}
                                title="Automação de Forças de Vendas"
                                text="Integre os processos e atividades do dia a dia do setor comercial, com o objetivo
                                de aumentar a produtividade, como o acompanhamento de oportunidades, emissão de propostas, assinatura de contrato digital, além da visão sintética das metas e o funil de vendas, incluindo campanhas e ações.
                                "
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <SolutionsCard
                                icon={CallCenter}
                                title="Call Center"
                                text="Gerencie a sua Central de Atendimento com apenas um canal de voz. Tenha em mãos as principais funções de um Call Center, como gerenciamento de filas de atendimento, gravação das ligações, monitoramento em tempo real das chamadas e relatórios gerenciais.
                                "
                            />
                        </div>
                        <div className="col-12 col-lg-6 p-5">
                            <div className="info-partners-card-title solution-text-title">
                                E muito mais...
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-solutions-pt6">
                <div className="container">
                    <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row justify-content-center align-items-center mt-5">
                                <img
                                    src={IconeChat}
                                    alt="chat"
                                    className="ml-5 mr-3"
                                />
                                <div className="text-box">
                                    <div className="text-1 font-weight-bold ">
                                        Vamos
                                    </div>
                                    <div className="text-1 font-weight-bold">
                                        conversar?
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-consultants mt-5">
                                FALE COM UM DE NOSSOS CONSULTORES{" "}
                                <div className="d-flex arrowDisplay">
                                    <RiArrowDownLine
                                        color="#fd7100"
                                        className="d-block ml-2"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="pg-solution">
                            <div className="container">
                                <div className="row solution">
                                    <section className="solution-form">
                                        <form>
                                            <input
                                                type="text"
                                                className="form-control solution-form__input"
                                                placeholder="NOME:"
                                            />
                                            <input
                                                type="text"
                                                className="form-control solution-form__input"
                                                placeholder="E-MAIL COMERCIAL:"
                                            />
                                            <input
                                                type="text"
                                                className="form-control solution-form__input"
                                                placeholder="CELULAR:"
                                            />
                                            <input
                                                type="text"
                                                className="form-control solution-form__input"
                                                placeholder="EMPRESA:"
                                            />
                                            <input
                                                type="text"
                                                className="form-control solution-form__input"
                                                placeholder="CARGO:"
                                            />
                                            <div className="d-flex flex-row mt-4">
                                                <input
                                                    type="radio"
                                                    className="mr-4 radio"
                                                />
                                                <div className="text-left text-accept-terms">
                                                    Eu aceito enviar meu dados
                                                    para receber conteúdo
                                                    educacional e promocional
                                                    através do e-mail, telefone
                                                    e outros meios eletrônicos.
                                                </div>
                                            </div>

                                            <button className="btn btn-primary btn-lg solution-form__button">
                                                Enviar
                                            </button>
                                        </form>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Solutions;
