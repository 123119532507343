import React from "react";

import logoTelFlex from "./../assets/images/logo-telflex-footer.png";
import logoDigivox from "./../assets/images/logo-digivox-footer.png";

import logoInstagram from "./../assets/images/instagram.png";
import logoFacebook from "./../assets/images/facebook.png";
import logoYoutube from "./../assets/images/youtube.png";
import logoLinkedin from "./../assets/images/linkedin.png";

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer-info d-flex flex-column flex-lg-row justify-content-lg-between align-items-center align-items-lg-start">
                    <div className="d-flex flex-column align-items-center mb-5 mb-lg-0">
                        <img src={logoTelFlex} alt="Telflex" className="mb-4" />
                        <small className="mb-2">Uma empresa do grupo</small>
                        <img src={logoDigivox} alt="Digivox" />
                    </div>

                    <div className="d-flex flex-column align-items-center align-items-lg-start mb-5 mb-lg-0">
                        <a href="#link" className="mb-4 h5">
                            Sobre
                        </a>
                        <a href="#link" className="mb-4 h5">
                            Soluções
                        </a>
                        <a href="#link" className="mb-4 h5">
                            Pacotes
                        </a>
                    </div>

                    <div className="d-flex flex-column align-items-center align-items-lg-start mb-5 mb-lg-0">
                        <a href="#link" className="mb-4 h5">
                            Perguntas frequentes
                        </a>
                        <a
                            href="https://materiais.digivox.com.br/telflex-campanha-de-lancamento-6-meses-gratis"
                            className="mb-4 h5"
                        >
                            Fale com um consultor
                        </a>
                        <a
                            href="docs/documento_política_de_privacidade.pdf"
                            className="mb-4 h5"
                        >
                            Política de Privacidade
                        </a>
                        <a
                            href="docs/Politica_de_Cookies_-_Digivox.pdf"
                            className="mb-4 h5"
                        >
                            Políticas de Cookies
                        </a>
                    </div>

                    <div className="d-flex flex-column align-items-center align-items-lg-start mb-5 mb-lg-0 text-center text-lg-left">
                        <div className="mb-2 h5">0800 724 8181</div>
                        <div className="mb-2">
                            João Pessoa | São Paulo | Miami
                        </div>
                        <div className="mb-2">
                            Rua Paulino Pinto, 1500, Cabo Branco, João Pessoa -
                            Paraíba
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="dpo">
                        <div>DPO Responsável: </div>
                        <div>Paulo Montenegro</div>
                        <div>dpo@digivox.com.br</div>
                    </div>
                    <div className="brand">
                        Telflex© {new Date().getFullYear()}
                    </div>
                    <div className="social">
                        <a href="https://www.instagram.com/telflex/">
                            <img src={logoInstagram} alt="Instagram" />
                        </a>
                        <a href="https://www.facebook.com/telflexbr">
                            <img src={logoFacebook} alt="Facebook" />
                        </a>
                        <a href="https://www.youtube.com/channel/UCTf1Q3qA8zfwf14ydxgoyWw">
                            <img src={logoYoutube} alt="Youtube" />
                        </a>
                        <a href="https://www.linkedin.com/company/telflexbr">
                            <img src={logoLinkedin} alt="Linkedin" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
