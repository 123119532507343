import React from "react";

interface IProps {
    className?: string;
}

const Text: React.FC<IProps> = ({ className, children }) => (
    <h1 className={`tf-text ${className}`}>{children}</h1>
);

export default Text;
