import React, { useState } from "react";
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import logo from "./../assets/images/logo-telflex.png";
import { RouteName } from "../routes/RouteName";

import { motion, useViewportScroll, useAnimation } from "framer-motion";

const Header: React.FC = () => {
    const [isOpen, setOpen] = useState(false);
    const { scrollYProgress } = useViewportScroll();

    const controls = useAnimation();

    scrollYProgress.onChange((e) => {
        let value = e * 100;

        controls.start({
            width: value + "%",
        });
    });

    function toggle() {
        setOpen(!isOpen);
    }

    return (
        <>
            <motion.div
                style={{
                    position: "fixed",
                    zIndex: 120,
                    width: "0%",
                    height: "2px",
                    backgroundColor: "#2e92a2",
                }}
                animate={controls}
                transition={{
                    duration: 0.6,
                }}
            />
            <Navbar color="light" light expand="lg">
                <div className="container">
                    <NavbarBrand href="#link">
                        <img src={logo} alt="TELFLEX" />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav
                            className="ml-auto d-lg-flex align-items-lg-center"
                            navbar
                        >
                            <NavItem className="mx-lg-3">
                                <NavLink
                                    className="nav-link"
                                    to={RouteName.HOME}
                                    exact
                                >
                                    INÍCIO
                                </NavLink>
                            </NavItem>
                            <NavItem className="mx-lg-3">
                                <NavLink
                                    className="nav-link"
                                    to={RouteName.ABOUT}
                                    exact
                                >
                                    SOBRE
                                </NavLink>
                            </NavItem>
                            <NavItem className="mx-lg-3">
                                <NavLink
                                    className="nav-link"
                                    to={RouteName.SOLUTIONS}
                                    exact
                                >
                                    SOLUÇÕES
                                </NavLink>
                            </NavItem>
                            <NavItem className="mx-lg-3">
                                <NavLink
                                    className="nav-link"
                                    to={RouteName.PRICE}
                                    exact
                                >
                                    PACOTES
                                </NavLink>
                            </NavItem>
                            <NavItem className="mx-lg-3">
                                <NavLink
                                    className="nav-link"
                                    to={RouteName.PARTNERS}
                                    exact
                                >
                                    PARCEIROS
                                </NavLink>
                            </NavItem>
                            <NavItem className="mx-lg-3">
                                <NavLink
                                    className="nav-link"
                                    to="/contact"
                                    exact
                                >
                                    CONTATO
                                </NavLink>
                            </NavItem>
                            <NavItem className="ml-lg-3">
                                <a
                                    className="btn btn-primary btn-lg"
                                    href="https://materiais.digivox.com.br/telflex-campanha-de-lancamento-6-meses-gratis"
                                >
                                    FALE COM UM CONSULTOR
                                </a>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
            <div className="navbar-base" />
        </>
    );
};

export default Header;
