import React, { useState, useEffect } from "react";

const AlertCookie: React.FC = () => {
    const [show, setShow] = useState(false);

    function close() {
        setShow(false);
        localStorage.setItem("alertCookie", "true");
    }

    function init() {
        let data = localStorage.getItem("alertCookie");
        if (!data) {
            setShow(true);
        }
    }

    useEffect(init, []);

    if (!show) {
        return <></>;
    }

    return (
        <div className="container-fixed">
            <div className="cookie-container">
                <div>
                    Nós ultilizamos cookies e tecnologias semelhantes para
                    melhorar a sua experiência com os nossos serviços, a fim de
                    personalizar as publicidades e recomendar conteúdo de seu
                    interesse, seguindo a nossa{" "}
                    <a href="#link">Política de Privacidade.</a> Ao continuar
                    navegando nesse site, você concorda com tal monitoramento.
                </div>
                <div>
                    <button onClick={close} className="cookie-btn">
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AlertCookie;
