import React from "react";

interface IProps {
    smallerType: boolean;
    title: string;
    text: string;
    ulType: boolean;
}

const AboutCard: React.FC<IProps> = ({ smallerType, title, text, ulType }) => (
    <div
        className={
            smallerType === true
                ? "about-card type-card-smaller flex-row d-flex mb-4"
                : "about-card type-card-bigger flex-row d-flex mb-4"
        }
    >
        <div className="d-flex flex-column">
            <div className="info-partners-card-title about-text-title">
                {title}
            </div>
            {ulType ? (
                <ul className="orangePings ">
                    <li>Espírito de equipe</li>
                    <li>Comprometimento</li>
                    <li>Foco no cliente</li>
                    <li>Iniciativa e proatividade</li>
                    <li>Aprendizagem Contínua</li>
                    <li>Ética, respeito e confiança</li>
                    <li>Criatividade e Inovação</li>
                </ul>
            ) : (
                <div className="info-partners-card-text about-text-subtitle mt-4">
                    {text}
                </div>
            )}
        </div>
    </div>
);

export default AboutCard;
