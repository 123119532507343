import React from "react";

interface IProps {
    icon: string;
    title: string;
    text: string;
}

const SolutionsCard: React.FC<IProps> = ({ icon, title, text }) => (
    <div className="solutions-card flex-row d-flex mb-4">
        <img src={icon} alt="icon" style={{ width: "45px", height: "52px" }} />
        <div className="d-flex flex-column ml-4">
            <div className="info-partners-card-title solution-text-title">
                {title}
            </div>
            <div className="info-partners-card-text solution-text-subtitle mt-4">
                {text}
            </div>
        </div>
    </div>
);

export default SolutionsCard;
