import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "../components/Loading";
import About from "../pages/About";
import Solutions from "../pages/Solutions";
import { RouteName } from "./RouteName";

const Home = lazy(() => import("./../pages/Home"));
const Partners = lazy(() => import("./../pages/Partners"));
const Contact = lazy(() => import("./../pages/Contact"));
const Price = lazy(() => import("./../pages/Price"));

const Routes: React.FC = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route path={RouteName.HOME} exact component={Home} />
                <Route path={RouteName.PARTNERS} exact component={Partners} />
                <Route path={RouteName.SOLUTIONS} exact component={Solutions} />
                <Route path={RouteName.CONTACT} exact component={Contact} />
                <Route path={RouteName.PRICE} exact component={Price} />
                <Route path={RouteName.ABOUT} exact component={About} />
                <Redirect to={RouteName.HOME} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
