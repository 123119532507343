import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBR from "./pt-Br";

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'pt-BR',
        lng: 'pt-BR',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            'pt-BR': ptBR
        }
    });


export default i18n;