import React from "react";

interface IProps {
    className?: string;
}

const Title: React.FC<IProps> = ({ className, children }) => (
    <h1 className={`tf-title ${className}`}>{children}</h1>
);

export default Title;
