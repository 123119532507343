import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AlertCookie from "./components/AlertCookie";
import GlobalProvider from "./contexts/GlobalProvider";
import Routes from "./routes/Routes";

const App: React.FC = () => {
    return (
        <Router>
            <GlobalProvider>
                <Routes />
            </GlobalProvider>
            <AlertCookie />
        </Router>
    );
};

export default App;
